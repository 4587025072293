$meeting-color1: #868686;

:root {
    --wallet-color1: #2f3542;
    --wallet-color2: #8e7011;
    --wallet-color3: #1a5338;
    --wallet-color4: #7d2930;
    --wallet-color5: #c23516;
}

.Wallet-page {
    .selector-button {
        button {
            padding-left: 20px;
            padding-right: 20px;

            @media(min-width:1750px) {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }

    .date-wrapper {

        input,
        .input-group-text,
        select {
            color: rgba(var(--bs-white-rgb), 0.80);
            border-color: var(--text-secondary);

            &::-webkit-calendar-picker-indicator {
                filter: invert(1);
            }
        }

        button {
            background-color: #6e6e6d;
            border-color: var(--text-secondary);
            min-width: 45px;
        }
    }

    .wallet-info-section {
        .info-card {
            background-color: var(--theme-color3);
        }

        .custom-bg {
            // background-color: var(--custom-bg);
            padding: 10px 12px;
        }

        .info-card .custom-bg {
            position: relative;

            &:after {
                content: '\F282';
                font-size: 20px;
                font-family: 'bootstrap-icons';
                display: flex;
                align-items: center;
                justify-content: center;
                height: 35px;
                width: 35px;
                background-color: #7d7d7d;
                border-radius: 50%;
                color: #fff;
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                transition: all .5s;
            }
        }

        .info-card:has(.accordion-collapse.show) .custom-bg:after {
            content: '\F286';
        }
    }

    .wallet-info-section .search-wrapper .dropdown-menu .custom-bg:after {
        display: none;
    }

    .card-header .custom-bg {
        @media(min-width:1200px) {
            .right-section {
                max-width: 40%;
            }
        }
    }

    .info-card.red-card .custom-bg {
        background-color: #8b3f46;
    }

    .info-card:has(.accordion-collapse.show) {
        border: 1px solid #979797;
    }

    .special-data-card {
        background: url('../resources/images/wallet/gift-incentive-bg.png') no-repeat;

        .custom-bg {
            background: none;
        }
    }

    .checklist-wrapper {
        ul {
            list-style-type: none;
            padding-left: 0;
        }

        li {
            background-image: url('../resources/images/wallet/checkmark.png');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: 0 5px;
            padding-left: 35px;
            margin-bottom: 10px;
        }
    }
}

// Redeem Amount Modal CSS
.redeemAmountModal {
    max-width: 913px;
    width: calc(100% - 20px);

    .checks-wrapper input[type="radio"]+label {
        color: #000000;
        opacity: 0.5;
    }

    .checks-wrapper input[type="radio"]:checked+label {
        color: var(--bs-black);
        opacity: 1;
    }

    .checks-wrapper input[type="checkbox"] {
        border-radius: 2px;
        accent-color: #000;
    }
}

// Dispute Modal CSS
.DisputeModal {
    max-width: 913px;
    width: calc(100% - 20px);

    .form-check-label {
        font-size: 15px;
    }

    .radio-wrapper input[type="radio"] {
        width: 18px;
        height: 18px;
    }

    .radio-wrapper.selectable-color input[type="radio"]+label {
        color: #000000;
        opacity: 0.5;
    }

    .radio-wrapper.selectable-color input[type="radio"]:checked+label {
        color: var(--bs-black);
        opacity: 1;
    }

    .radio-wrapper input[type="radio"] {
        accent-color: #000;
    }

    .checks-wrapper input[type="checkbox"] {
        border-radius: 2px;
    }
}

// Add overlay when click on search to add field
.toggled-class .main-content {
    position: relative;

    &:before {
        content: '';
        display: block;
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }
}

// Read By button with dropdown and badge counter css
.button-container .name-list {
    display: none;
}

.button-container .name-list p:hover {
    background: #f0f0f0;
}

.button-container:hover .name-list {
    display: block;
}

// Incentives Page CSS
.incentives-page {
    .tabs-wrapper {
        .nav-tabs {
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            border-color: #464845;
            border-width: 1.5px;

            .nav-link {
                color: var(--text-secondary);
                padding: 8px 1rem;
                border: 0;
                border-bottom: 5px solid transparent;
                white-space: nowrap;

                @media(min-width:992px) {
                    padding: 8px 2rem;
                }

                &:not(.active):hover,
                &:outline {
                    border: 0 !important;
                }

                &.active {
                    color: var(--bs-white);
                    background-color: transparent;
                    border-bottom: 5px solid var(--bs-primary) !important;
                }
            }
        }
    }
}

// Opportunity Details Page CSS
.OpportunityDetails-page {
    .details-card .info-box {

        .label,
        .value {
            @media(min-width:576px) {
                width: 50%;
            }
        }
    }

    .card-wrapper {
        .info-card {
            background-color: #585858;

            &.active {
                border: 1.5px solid #3dd54c;
            }
        }

        .opportunity-added {
            white-space: normal;
        }
    }

    .info-box {
        .form-check-input:checked[type=radio]+label {
            color: var(--bs-white);
        }

        textarea::placeholder {
            color: #a6a6a6;
            opacity: 1;
        }
    }
}

// Wallet Dashboard Page CSS
.WalletDashboard {
    .wallet-user-listing {
        .user-info {
            width: 250px;
            background-color: var(--wallet-color1);
            border: 3px solid var(--wallet-color1);
            padding: 12px;
        }
    }

    .info-cards-section .info-card {
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 0px 0px 5px #858585;

        table th,
        table td {
            padding: 7px;
            white-space: nowrap;
            vertical-align: middle;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    .transactions-lists {
        .list-item {
            background-color: #2c3f50;
        }
    }

    // Slick Dots Style Customisation
    .custom-slider {
        .slick-dots li button {
            background-color: var(--bs-warning) !important;
            height: 12px !important;
            width: 12px !important;
            border-radius: 50%;
            border: none;
            opacity: 0.5;

            &:before {
                display: none;
            }
        }

        .slick-dots li button:hover {
            opacity: 1;
        }

        .slick-dots li.slick-active button {
            opacity: 1;
        }
    }

    .announcement-section {
        .slick-list {
            margin: 0 0 0 0.5px;
            /* Remove any margin */
            padding: 0;
            /* Remove unnecessary padding */
        }

        .slick-dots {
            bottom: 0;
            left: -5px;
            text-align: left;
        }
    }

    .earning-section {
        .user-incentive {
            .icon {
                height: 35px;
                min-width: 35px;
                width: 35px;

                i {
                    @media(min-width:1700px) {
                        font-size: 25px;
                    }
                }

                @media(min-width:1700px) {
                    height: 50px;
                    min-width: 50px;
                    width: 50px;
                }
            }

            .user-title {
                font-size: 12px;

                @media(min-width:1201px) and (max-width:1540px) {
                    font-size: 10px !important;
                }

                @media(min-width:1540px) {
                    font-size: 12px !important;
                }
            }

            .heading-label {
                @media(min-width:1201px) and (max-width:1540px) {
                    font-size: 16px !important;
                }

                @media(min-width:1540px) {
                    font-size: 18px !important;
                }
            }
        }
    }

    .overall-stats-section {
        .slick-dots {
            bottom: 0;
        }
    }
}

.DisputeDetails {
    .checklist-wrapper {
        ul {
            list-style-type: none;
            padding-left: 0;
        }

        li {
            background-image: url('../resources/images/wallet/checkmark.png');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: 0 5px;
            padding-left: 35px;
            margin-bottom: 10px;
        }
    }
}

// Management Wallet Dashboard Page CSS
.ManagementWalletDashboard-page {
    .earning-section .info-box {
        @media(min-width:1024px) {
            max-width: 240px;
        }
    }
}

/* HTML: <div class="loader"></div> */
.search-op-loader {
    width: 120px;
    height: 20px;
    background:
        linear-gradient(90deg, #0000, #ffa500) left -50px top 0/50px 20px no-repeat lightblue;
    animation: l2 1s infinite linear;
}

@keyframes l2 {
    100% {
        background-position: right -50px top 0
    }
}

/* Full-screen overlay styles */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    /* Semi-transparent white background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* Ensures it is above all other content */
}

.border-green{
    border: #3dd54c;
}
.border-orange{
    border:#ffa500
}

/* HTML: <div class="loader"></div> */
.qr-loader {
    width: 40px;
    height: 40px;
    --c:no-repeat linear-gradient(orange 0 0);
    background: var(--c),var(--c),var(--c),var(--c);
    background-size: 21px 21px;
    animation: l5 1.5s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l5 {
   0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
   33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
   66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
   100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}

// background blur
.background-blur {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(2px);
}

// another loader
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}