.MailList-page {
    @media(min-width: 768px) {
        max-height: calc(100vh - 56px);
    }

    overflow: hidden;

    .email-menu-section {
        overflow-x: auto;

        @media(min-width: 992px) {
            width: 150px;
        }

        li {
            a {
                background: transparent;
                color: var(--bs-white);
                white-space: nowrap;

                i {
                    opacity: 0.7;
                    margin-right: 3px;
                }

                &:hover {
                    background: var(--theme-color3);
                }
            }

            a.active {
                background: linear-gradient(168.68deg, rgb(0, 82, 201) 1.12%, rgb(10, 145, 219));

                i {
                    opacity: 1;
                }
            }
        }
    }

    .email-details-section {
        @media(min-width: 992px) {
            width: calc(100% - 150px);
        }


        .user-list-section {
            height: 350px;
            overflow: auto;

            @media(min-width: 768px) {
                height: calc(100vh - 209px);
            }

            @media(min-width: 992px) {
                height: calc(100vh - 162px);
            }

            .client-card {
                background-color: var(--theme-color3);

                &.selected {
                    background: linear-gradient(168.68deg, rgb(0, 82, 201) 1.12%, rgb(10, 145, 219));
                }
            }
        }

        .user-details-section {
            overflow: auto;

            @media(min-width: 768px) {
                height: calc(100vh - 209px);
            }

            @media(min-width: 992px) {
                height: calc(100vh - 162px);
            }

            .info-card {
                background-color: var(--theme-color3);
                .description-wrapper *{
                    color: var(--text-secondary) !important; 
                }
            }
        }
    }

    // common css for email section
    .files-wrapper {
        .file-btn {
            max-width: 200px;
        }
    }
}

.new-mail-page {
    .user-form-details {
        .custom-select__input-container {
            color: rgba(var(--bs-white-rgb), 0.75);
        }

        .custom-select__control:hover {
            border-color: var(--bs-secondary);
        }

        .cc-user-input-css .custom-select__control {
            border-right: 0;
            border-radius: 0;
        }

        .user-subject-input-css {
            color: rgba(var(--bs-white-rgb), 0.85);
            background-color: var(--dark-theme-bg);
            border-radius: .375rem;
            min-height: 46px;

            &::placeholder {
                color: var(--text-secondary);
                font-size: 15px;
            }

            ::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: var(--text-secondary);
                font-size: 15px;
            }

            ::-moz-placeholder {
                /* Firefox 19+ */
                color: var(--text-secondary);
                font-size: 15px;
            }

            :-ms-input-placeholder {
                /* IE 10+ */
                color: var(--text-secondary);
                font-size: 15px;
            }

            :-moz-placeholder {
                /* Firefox 18- */
                color: var(--text-secondary);
                font-size: 15px;
            }
        }

        .custom-quill-editor {
            color: rgba(var(--bs-white-rgb), 0.85);
            // min-height: 200px;
            .ql-snow {
                border: 0;
            }

            .ql-toolbar {
                background-color: #959394;

                &:before {
                    content: 'Draft with Sheela';
                    color: var(--bs-light);
                    background-color: var(--custom-bg);
                    border-radius: 10px;
                    font-size: 14px;
                    padding: 5px 10px;
                    background-image: url('../resources/images/upArrow.png');
                    background-size: 15px;
                    background-repeat: no-repeat;
                    background-position: 10px;
                    padding-left: 30px;
                }
            }

            .ql-container {
                overflow: auto;
                padding: 7px 0 10px;

                .ql-editor {
                    font-size: 14px;
                    padding: 5px 8px;

                    &.ql-blank::before {
                        color: var(--text-secondary);
                        font-size: 15px;
                        font-style: normal;
                    }
                }
            }
        }

        .submit-msg-wrapper {
            background-color: #595959;

            input {
                background-color: transparent;
            }
        }
    }
}

.gmail_quote{
    display:none;
}