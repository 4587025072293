.ceo-updates-section {
    .ceo-details {
        @media(min-width: 576px) {
            margin-left: 59px;
        }
    }
}

.newNoticeModal {
    .ql-container {
        max-height: 40vh !important;
    }
}

.red-border-important {
    border: 1px solid red !important;
  }

