.spinner-profile {
  /* Spinner size and color */
  width: 3.5rem;
  height: 3.5rem;
  border-top-color: #444;
  border-left-color: #444;

  /* Additional spinner styles */
  animation: spinner-profile 400ms linear infinite;
  border-bottom-color: #fff;
  border-right-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;  
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}

/* Animation styles */
@keyframes spinner-profile {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}