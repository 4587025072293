.room_container {
  height: var(--body-height1);
  background-color: var(--dark-theme-bg2);
  /* margin-top: var(--topbar-height); */
  transition: all .5s;
}

.new_message_input {
  border: 1px solid #d1dbe2;
  padding: 10px 50px 10px 15px;
  color: #fff;
  font-size: 16px;
  width: 100%;
  box-shadow: 2px 20px 5px rgba(0, 82, 201, 0.05);
}

.new_message_input:focus,
.new_message_textarea:focus {
  outline: none;
}

.new_messsage_button {
  position: absolute;
  right: 40px;
}

.message_container {
  display: flex;
  flex-direction: column;
}

.message_container:last-child p {
  margin-bottom: 0;
}

.message_align_left {
  align-items: flex-start;
}

.message_align_right {
  align-items: flex-end;
}

.message_left_styles {
  background: linear-gradient(168.68deg, #0052c9 1.12%, #0a91db 100%);
  color: white;
}

.message_right_styles {
  background: linear-gradient(168.68deg, #0052c9 1.12%, #0a91db 100%);
  color: white;
}

.message_title {
  margin: 0 0 10px 0;
  padding: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.message_content {
  /* margin: 0px 10px; */
  padding: 5px 12px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;
}

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: rgba(0, 188, 164, 0.1);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 10px;
}

.overlay_container {
  width: 100%;
  height: 100vh;
  /*background: rgba(0, 82, 201, 0.7);*/
  background: #9ca5ab;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  /*border-top: 16px solid #0052c9; /* Blue */
  border-top: black;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.participants_label_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.local_screen_share_preview {
  position: absolute;
  width: 15%;
  height: 90%;
  background-color: black;
  border-radius: 8px;
  border: 2px solid blue;
  left: 1%;
  bottom: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_buttons_container {
  width: 60%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 20%;
  bottom: 0;
  /*background: linear-gradient(168.68deg, #0052c9 1.12%, #0a91db 100%);*/
  background-color: #9ca5ab;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.videos_container {
  height: 90%;
  width: 100%;
  background-color: #f6f9fb;
  position: relative;
  border-radius: 0px 0px 25px 25px;
}

.participants {
  display: flex;
  width: 100%;
  height: 100%;
}

.participant {
  flex-grow: 1;
}

.video,
.audio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.screen_share_track {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}

.room {
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.room_label {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 4;
}

.room_label_paragraph {
  font-size: 17px;
  color: white;
  margin-top: 0;
  background-color: #9ca5ab;
}

.video_track_container.current-user {
  width: 260px !important;
  height: 170px !important;
  border-color: var(--theme-blue1) !important;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 11;
}

/* Call On Mobile Model */
.topbar .mobile-call i {
  color: var(--bs-warning);
  display: inline-block;
  animation-name: trin;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes trin {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
    color: var(--bs-warning);
  }

  20%,
  32%,
  44%,
  56%,
  68% {
    transform: rotate3d(0, 0, 1, 0deg);
    color: var(--bs-warning);
  }

  23%,
  35%,
  47%,
  59%,
  71% {
    transform: rotate3d(0, 0, 1, 15deg);
    color: var(--bs-warning);
  }

  26%,
  38%,
  50%,
  62%,
  74% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  29%,
  41%,
  53%,
  65%,
  77% {
    transform: rotate3d(0, 0, 1, -15deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  100% {
    color: red;
  }
}

.call_mobile_model .modal-body {
  color: var(--bs-gray-300);
}

.call_mobile_model .user-status button {
  transition: linear .4s;
}

.call_mobile_model .user-status button:hover {
  transform: scale(1.1);
}

@media (max-width: 1199.98px) {
  .audio-user-wrapper img {
    max-width: 80px;
    max-height: 80px;
  }
}




/* Large devices (desktops, 992px and up) */

@media (min-width: 1200px) {

  .audio-user-wrapper img {
    max-width: 150px;
    max-height: 150px;
  }
}

.call_mobile_model .cancel-icon {
  height: 35px;
  width: 35px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 11;
  cursor: pointer;
  transition: all .5s;
}

.call_mobile_model .cancel-icon:hover {
  opacity: 0.7;
}

.modal#audioModel {
  background-color: rgba(0, 0, 0, 0.5);
}

/* When we calling screen css */
.when-calling .caller-wrapper {
  max-width: 300px;
}

.when-calling .caller-wrapper .chip-img {
  height: 35vw;
  width: 35vw;
  max-width: 200px;
  max-height: 200px;
}

.when-calling .receiver-wrapper {
  max-width: 180px;
  width: 100%;
  background-color: var(--theme-color3);
  padding: 1.5rem 1rem;
}

.when-calling .receiver-wrapper .chip-img {
  height: 60px;
  width: 60px;
}

@media (min-width: 768px) {
  .when-calling .receiver-wrapper {
    max-width: 250px;
    padding: 2.5rem 1rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1200px) {

  .audio-user-wrapper img {
    max-width: 150px;
    max-height: 150px;
  }
}

@media (max-width: 1199.98px) {
  .audio-user-wrapper img {
    max-width: 80px;
    max-height: 80px;
  }
}