/* The container that holds the loader */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Overlay background */
    z-index: 9999; /* Make sure it's on top of other content */
  }
  
  /* Spinner design */
  .spinner {
    border: 8px solid #f3f3f3; /* Light gray background color */
    border-top: 8px solid #3498db; /* Blue color for the spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Animation to rotate the spinner */
  }
  
  /* Keyframes for the spinner animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  