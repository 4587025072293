/* HTML: <div class="loader"></div> */
.loader-qq {
    width: 120px;
    height: 22px;
    border-radius: 20px;
    color: #514b82;
    border: 2px solid;
    position: relative;
    margin:0 auto;
    top:5px
  }
  .loader-qq::before {
    content: "";
    position: absolute;
    margin: 2px;
    inset: 0 100% 0 0;
    border-radius: inherit;
    background: currentColor;
    animation: l6 2s infinite;
  }
  @keyframes l6 {
      100% {inset:0}
  }