#loading-bar-spinner.spinner {
    /* left: 50%;
    margin-left: -20px;
    top: 50%;*/
    /* margin-top: -40px; 
    position: absolute;
    z-index: 19 !important; */
    animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
    width: 40px;
    height: 40px;
    border:  solid 4px transparent;
    border-top-color:  #00C8B1 !important;
    border-left-color: #00C8B1 !important;
    border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}
.hide{
    display: none !important;
}


/* preloader for evalution revelance modal */

.spinner-relevance {
    /* Spinner size and color */
    width: 2.5rem;
    height: 2.5rem;
    border-top-color: #444;
    border-left-color: #444;
  
    /* Additional spinner styles */
    animation: spinner-relevance 400ms linear infinite;
    border-bottom-color: #fff;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;  
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
  
  /* Animation styles */
  @keyframes spinner-relevance {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .issues-spinner {
    /* Spinner size and color */
    width: 2.5rem;
    height: 2.5rem;
    border-top-color: #444;
    border-left-color: #444;
  
    /* Additional spinner styles */
    animation: issues-spinner 400ms linear infinite;
    border-bottom-color: #fff;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;  
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
  
  /* Animation styles */
  @keyframes issues-spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .replies-spinner {
    /* Spinner size and color */
    width: 2.5rem;
    height: 2.5rem;
    border-top-color: #444;
    border-left-color: #444;
  
    /* Additional spinner styles */
    animation: replies-spinner 400ms linear infinite;
    border-bottom-color: #fff;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;  
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
  
  /* Animation styles */
  @keyframes replies-spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }



  .lead-list-spinner {
    /* Spinner size and color */
    width: 2.5rem;
    height: 2.5rem;
    border-top-color: #444;
    border-left-color: #444;
  
    /* Additional spinner styles */
    animation: lead-list-spinner 400ms linear infinite;
    border-bottom-color: #fff;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;  
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
  
  /* Animation styles */
  @keyframes lead-list-spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }



  .lead-description-page-spinner {
    /* Spinner size and color */
    width: 2.5rem;
    height: 2.5rem;
    border-top-color: #444;
    border-left-color: #444;
  
    /* Additional spinner styles */
    animation: lead-description-page-spinner 400ms linear infinite;
    border-bottom-color: #fff;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;  
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
  
  /* Animation styles */
  @keyframes lead-description-page-spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }



  
  .meeting-pointer-spinner {
    /* Spinner size and color */
    width: 2.5rem;
    height: 2.5rem;
    border-top-color: #444;
    border-left-color: #444;
  
    /* Additional spinner styles */
    animation: meeting-pointer-spinner 400ms linear infinite;
    border-bottom-color: #fff;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;  
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
  
  /* Animation styles */
  @keyframes meeting-pointer-spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  
  .cam-bdm-feedback-spinner {
    /* Spinner size and color */
    width: 2.5rem;
    height: 2.5rem;
    border-top-color: #444;
    border-left-color: #444;
  
    /* Additional spinner styles */
    animation: cam-bdm-feedback-spinner 400ms linear infinite;
    border-bottom-color: #fff;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;  
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
  
  /* Animation styles */
  @keyframes cam-bdm-feedback-spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }