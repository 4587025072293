// Start: Daily work update page css
.SDMWorkUpdate-page {
    .custom-page-wrapper {
        min-height: calc(100svh - 56px);
    }
}

// End: Daily work update page css

// Month view section css
.FullCalendar-wrapper {
    a {
        color: var(--bs-light);
        text-decoration: none;
    }

    .progress {
        height: 26px;
        // @media(min-width:992px){
        //     height: 29px;
        // }
    }

    // hide day view default layout
    // &.day-view-layout .fc-view-harness {
    //     visibility: hidden;
    //     opacity: 0;
    //     height: 0!important;
    // }

    .fc-media-screen {
        min-height: 500px;

        @media (min-width: 1200px) {
            max-height: 79vh;
            height: 100%;
        }

        .date-title,
        .fc-col-header-cell-cushion {
            @media(max-width:991px) {
                font-size: 14px;
            }
        }

        .event-title {
            font-size: 12px;

            @media(min-width:991px) {
                font-size: 14px;
            }
        }
    }

    .fc-toolbar.fc-header-toolbar {
        margin-bottom: 0.875rem;

        @media (max-width: 991px) {
            flex-wrap: wrap;
            gap: 12px;
        }

        @media (min-width: 992px) {
            .fc-toolbar-chunk {
                width: 33.3333%;

                &:last-child {
                    text-align: right;
                }

                &:nth-child(2) {
                    text-align: center;
                }
            }
        }

        .fc-toolbar-title {
            color: var(--bs-white);
            font-weight: 600;
            line-height: normal;
        }
    }

    .fc-toolbar-chunk button {
        border-color: var(--bs-secondary) !important;
        font-size: 0.875rem;

        &:focus {
            box-shadow: none !important;
        }
    }

    .fc-toolbar-chunk:first-child .fc-button-group {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 3px 5px;

        button {
            border: 0;
            border-radius: 4px !important;
            font-weight: 600;
            min-width: 135px;
        }

        button:not(.fc-button-active) {
            background-color: transparent !important;
            color: rgba(var(--bs-light), 0.5);
        }

        button.fc-button-active {
            background-color: var(--bs-primary) !important;
        }
    }

    .fc-toolbar-chunk .fc-today-button {
        border-color: var(--bs-primary) !important;
        border-radius: 4px !important;
        font-weight: 600;
        min-width: 140px;

        &:not(disabled) {
            background-color: var(--bs-primary) !important;
        }
    }

    .fc-theme-standard {
        th {
            background-color: var(--bs-primary);
            border-color: var(--bs-primary);

        }

        .fc-col-header-cell {
            font-weight: 600;
            padding: 5px 1px;
            text-align: left;

            @media (min-width:992px) {
                padding: 5px 6px;
            }
        }

        .fc-daygrid-day-frame {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 5px;
            font-weight: 500;

            @media (min-width:992px) {
                padding: 10px;
                font-weight: 700;
            }

            .fc-daygrid-day-top {
                flex-direction: row;
                flex: 1;

                .fc-daygrid-day-number {
                    width: 100%;
                    padding: 0;
                }
            }

            .fc-daygrid-day-events {
                // position: absolute;
                // bottom: 15px;
                // left: 10px;
                // right: 10px;
                min-height: auto;

                .fc-h-event {
                    background-color: transparent;
                    border: none;
                    margin: 0;
                }
            }
        }

        .fc-scrollgrid,
        td {
            border-color: #464845;
            background-color: #353633;
        }
    }

    .fc-scrollgrid-sync-table tbody tr:nth-child(6) {
        display: none;
    }

    .fc-timeGridDay-view .fc-daygrid-day-events {
        margin-bottom: 0;
    }
}

.MonthViewLayout {

    // update menu hover dropdown
    .client-update-menu {
        position: relative;

        &:hover .clients-list-cover {
            display: block;
        }

        .clients-list-cover {
            display: none;
            max-width: 400px;
            padding-top: 14px;

            @media(min-width:576px) {
                min-width: 400px;
            }
        }

        .clients-list {
            background-color: #319c37;
            position: relative;

            &:before {
                content: "";
                background-color: #319c37;
                height: 15px;
                width: 15px;
                display: block;
                position: absolute;
                top: -7.5px;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                z-index: 11;
            }

            thead tr {
                border-bottom: 2px solid rgba(255, 255, 255, 0.5);
            }

            tbody tr:not(:last-child) {
                border-bottom: 1.5px solid rgba(255, 255, 255, 0.06);
            }

            tbody tr td:last-child {
                border-left: 1.5px solid rgba(255, 255, 255, 0.06);
            }
        }
    }

    .progress-bar.bg-success {
        background-color: #319c37 !important;
    }
}

// Daily view section css
.DayViewLayout {

    .work-cards-wrapper .info-card:has(.accordion-collapse.show) .custom-bg:after {
        content: "\f286";
    }

    .work-cards-wrapper .info-card .custom-bg:after {
        content: "\f282";
        font-size: 20px;
        font-family: "bootstrap-icons";
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 35px;
        background-color: #7d7d7d;
        border-radius: 50%;
        color: #fff;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.5s;
    }

    .content-wrapper {
        .description {
            font-size: 1rem;

            @media(min-width: 768px) {
                font-size: 22px;
            }
        }
    }
}

// Work Update Form Modal CSS
.work-update-modal {
    max-width: calc(100% - 20px);

    @media(min-width:768px) {
        max-width: calc(100% - 50px);
        width: 1600px;
    }

    .billing-card {
        min-height: 45px;
    }

    .form-control,
    .form-select {
        min-height: 45px;
    }

    .radio-wrapper,
    .checks-wrapper {
        padding-left: .75rem;
    }

    .emp-info-section .info-box {
        background-color: #f4f4f4;
    }

    .billing-info-card {
        background-color: #f4f4f4;
    }
}

.skeleton {
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

/* HTML: <div class="loader-sdm-update"></div> */
.loader-sdm-update {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #0000;
    border-right-color: #ffa50097;
    position: relative;
    animation: l24 1s infinite linear;
  }
  .loader-sdm-update:before,
  .loader-sdm-update:after {
    content: "";
    position: absolute;
    inset: -8px;
    border-radius: 50%;
    border: inherit;
    animation: inherit;
    animation-duration: 2s;
  }
  .loader-sdm-update:after {
    animation-duration: 4s;
  }
  @keyframes l24 {
    100% {transform: rotate(1turn)}
  }

  .fc-daygrid-day {
    cursor: pointer;
}