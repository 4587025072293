$meeting-color1: #868686;

.Announcement-page {
    .announcement-details {
        @media(min-width: 576px) {
            margin-left: 58px;
        }
    }
}

// New Announcement Modal
.newAnnouncementModal {
    .form-control {
        min-height: 46px;

        &::placeholder {
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }
    }

    .form-control[type="datetime-local"]:invalid::-webkit-datetime-edit {
        color: $meeting-color1;
        font-size: 16px;
        text-transform: uppercase;
    }

    .form-control[type="datetime-local"]:invalid::-webkit-calendar-picker-indicator {
        filter: invert(0.5);
    }
}

// Delete Announcement Modal
.deleteAnnouncementModal {
    max-width: 750px;
    width: calc(100% - 20px);
}