// Start: Custom date-range-picker css
.custom-date-range {
  position: relative;

  &:after {
    content: '\F1E2';
    font-family: 'bootstrap-icons';
    color: #6e6e6e;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    z-index: 11;
  }

  &>input {
    min-width: 260px;
    padding-right: 35px;
  }

  .date-range-chart {
    position: absolute;
    top: calc(100% + 3px);
    right: 0;
    z-index: 1111;
  }
}

// End: Custom date-range-picker css
.pagination li a:not(.active) {
  background-color: var(--custom-bg);
  border-color: var(--bs-gray-600);
  box-shadow: none;
  color: var(--text-secondary);
}

// Start: My Requisition Details Page CSS 
.dashboard-home .info-card-sec .card-icon {
  width: 45px;
  min-width: 45px;
  height: 45px;
}

.cards-listing {
  .info-cards {
    background-color: #d6d6d6;

    .info-box {
      column-gap: 0.5rem
    }
  }

  .int-scheduled .info-header {
    background-color: #5473dd;
  }

  .int-started .info-header {
    background-color: #df355f;
  }

  .int-status .info-header {
    background-color: #1fb11f;
  }

  .int-profile-created .info-header {
    // background-color: var(--bs-warning);
    background-color: #0dcaf0;
  }

  .int-result .info-header {
    background-color: var(--bs-success);
  }
}

// End: My Requisition Details Page CSS


// Start: Call History Details Page CSS
.call-history-details {

  .client-list-section,
  .client-info-details {
    height: calc(100vh - 88px);
    max-height: calc(100vh - 88px);
  }

  .client-list-section .requrement-wrapper .description {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .client-detail-wrapper .client-card.selected:after {
    top: auto;
    right: 8px;
    bottom: -10px;
  }

  .meeting-details-page .comment-part:after {
    border-top: 15px solid var(--custom-bg);
    border-right: 15px solid var(--custom-bg);
    border-bottom: 15px solid transparent;
    transform: rotate(45deg) translateY(-50%);
    top: 50%;
    right: 8px;
  }

  .client-detail-wrapper .client-card:first-child {
    margin-top: 0 !important;
  }
}

.custom-conference-wrapper {
  .dialpad-wrapper {
    max-width: 100%;
    @media(min-width:992px){
      width: 40%;
      max-width: 100%;
    }
  }

  .transcript-section-wrapper {
    @media(min-width:992px){
      width: 60%;
      border-left: 1px solid #eee;
    }
  }
}

// End: Call History Details Page CSS

// Start: Dialpad Section CSS
.dialpad-wrapper {
  max-width: 300px;

  .dialpad-screen,
  .callign-screen,
  .confrence-call-screen {
    background-color: var(--dark-theme-bg2);

    .top-section {
      background-color: var(--theme-color3);

      input {
        font-size: 1.75rem;
        font-weight: 200;
        text-align: center;
      }
    }

    .bottom-section {
      .num {
        color: var(--bs-white);
        font-size: 1.5rem;
        font-weight: 300;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--dark-theme-bg);
        margin: 0 auto;
        padding: 5px;
      }
    }

    .action-wrapper button {
      width: 45px;
      height: 45px;
    }
  }
}

// End: Dialpad Section CSS

// Start: Profile Bio Page CSS
.profilePage {
  .dashboard-component {
    .img-wrapper {
      min-width: 161px;
      width: 161px;
      height: 161px;

      .user-pc-progress {
        width: 161px;
        position: absolute;
        top: 0;
        left: 0;

        .bg {
          stroke: #f7f7f9;
        }
      }

      .photoTrigger {
        width: 130px;
        height: 130px;

        // .user-percentage {
        //   margin-bottom: -18px;
        // }

        .user-percentage {
          font-size: 12px;
          font-weight: 700;
          margin-bottom: -18px;
          padding: 2px 12px;
          text-align: center;
        }

        .photoBadge {
          width: 130px;
          height: 130px;
          background-color: rgba(0, 0, 0, .6);
          color: #f7f7f9;
          font-size: 11px;
          display: none;
        }

        &:hover .photoBadge {
          display: flex;
        }
      }
    }

    .profile-info {
      .availibility-icon {
        position: relative;

        &:before {
          content: "";
          display: block;
          background-color: var(--bs-white);
          height: 7px;
          width: 7px;
          position: absolute;
          z-index: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .left-box {
        @media(min-width: 1200px) {
          border-right: 1px solid #4b4b4c;
        }
      }
    }

    .pending-actions {
      .pend-icon {
        min-width: 36px;
        width: 36px;
        height: 36px;
      }

      .pend-item-progress {
        padding: 2px 8px;
        min-width: 50px;
        text-align: center;
      }

    }
  }

  // It skills card table
  .table-custom {

    tr th,
    tr td {
      background: transparent;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .card-info-section .menu-links {
    padding: 8px 12px;
    &:hover {
      background-color: var(--bs-gray-800);
    }

  }

  .edit-icon {
    cursor: pointer;
  }
}

.profileCustomModal {
  @media (min-width:992px) {
    &.modal-dialog {
      max-width: 750px;
    }
  }

  .close-wrapper button {
    line-height: normal;
  }

  &.mw-auto {
    max-width: fit-content;
  }

  // react-select customization
  .custom-select {
    .custom-select__control {
      color: var(--bs-body-color);
      background-color: var(--bs-white);
      border-color: var(--bs-border-color) !important;
      border-radius: 1rem;
      min-height: 45px;
    }

    .custom-select__value-container {
      padding: .375rem .75rem;
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--theme-color4);
    font-size: 14px;
    font-weight: 300;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--theme-color4);
    font-size: 14px;
    font-weight: 300;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: var(--theme-color4);
    font-size: 14px;
    font-weight: 300;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: var(--theme-color4);
    font-size: 14px;
    font-weight: 300;
  }

  .widgetTitle {
    line-height: 1.25;
  }

  .heading-wrapper .subTitle {
    color: #717b9e;
  }

  .form-label {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .form-check-label {
    color: #717b9e;
    font-weight: 500;
  }

  .form-check-input:checked+.form-check-label {
    color: var(--bs-body-color);
  }

  .form-control,
  .form-select {
    color: var(--bs-body-color);
    font-size: 14px;
    min-height: 45px;
    border: 1px solid var(--bs-border-color);
    border-radius: 1rem;
  }
}

// Edit mobile number modal
.editMobileNumModal {
  max-width: 450px!important;
}
// Modal within modal
.modal-backdrop.child-modal-backdrop {
  z-index: 1055; /* Set a higher z-index for the child modal's backdrop */
}
// End: Profile Bio Page CSS

// Start: Registration Page CSS
.profile-registration {
  .regis-info-list {
    .icon {
      height: 18px;
      width: 18px;
      min-width: 18px;
      border-radius: 50%;
      background-color: #47b749;
      margin-top: 2px;
    }
  }

  .work-status-wrapper {
    .btn-check:checked+.work-details {
      border-color: var(--bs-warning) !important;
    }
  }

  .profileCustomModal label.form-label {
    color: var(--bs-white);
  }

  .right-sec .card {
    @media(min-width: 991px) {
      min-height: 500px;
    }
  }
}

  // End: Registration Page CSS