.notes-page {

    // overflow: hidden;
    .date-wrapper {
        input {
            color: rgba(var(--bs-white-rgb), 0.80);
            border-color: var(--text-secondary);

            &::-webkit-calendar-picker-indicator {
                filter: invert(1);
            }
        }

        button {
            background-color: #6e6e6d;
            border-color: #6e6e6d;
            min-width: 45px;
        }
    }

    @media(min-width: 768px) {
        max-height: calc(100vh - 56px);
    }

    .notes-list-section {
        height: 450px;
        overflow: auto;

        @media(min-width: 768px) {
            height: calc(100vh - 209px);
        }

        @media(min-width: 992px) {
            height: calc(100vh - 162px);
        }

        .client-card {
            background-color: var(--theme-color3);

            &.selected {
                background: linear-gradient(168.68deg, rgb(0, 82, 201) 1.12%, rgb(10, 145, 219));
            }
        }

        .searchfield-wrapper input[type="search"] {
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: var(--text-secondary);
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: var(--text-secondary);
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                color: var(--text-secondary);
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: var(--text-secondary);
            }
        }
    }

    .notes-details-section {
        overflow: auto;

        @media(min-width: 768px) {
            height: calc(100vh - 209px);
        }

        @media(min-width: 992px) {
            height: calc(100vh - 162px);
        }

        .top-info-section {
            .title-label {
                @media(min-width: 992px) {
                    width: 115px;
                    min-width: 115px;
                }
            }
        }

        .info-card {
            background-color: var(--theme-color3);
        }

        .view-more-box {
            background-color: #4d4b4c;
        }
    }

    .files-wrapper {
        .file-btn {
            max-width: 200px;
        }
    }
}

.notes-stepper {
    margin: auto;
    text-align: center;

    .stepper-step {
        position: relative;

        .step-circle {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #e0e0e0;
            transition: background-color 0.3s;
        }

        &.active .step-circle {
            background-color: #3f51b5;
            color: white;
        }
    }

    .stepper-step:not(:last-child) .step-circle:before {
        content: '';
        height: 1px;
        width: 100%;
        background-color: #cdcdcc;
        position: absolute;
        left: 50%;
        top: 7.5px;
        z-index: -1;
    }

    .stepper-controls button {
        padding: 5px 12px;
        // background-color: #3f51b5;
        background-color: var(--custom-bg);
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
        border: 1px solid var(--bs-gray-600);
        box-shadow: none;
        color: var(--bs-white);

        &.btn-prev {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.btn-next {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

    .stepper-controls button:disabled {
        background-color: var(--custom-bg);
        cursor: not-allowed;
    }
}

// Extended Notes Page CSS
.note-extended-page {
    .notes-stepper .stepper-step .step-circle {
        background-color: var(--dark-theme-bg2);

        &:before {
            background-color: #737371;
        }
    }

    .notes-stepper .stepper-step.open .step-circle {
        background-color: var(--bs-primary);
        border-color: var(--bs-primary);

        &:before {
            background-color: var(--bs-white);
        }
    }

    .notes-stepper .stepper-step.lead .step-circle {
        background-color: var(--theme-color6);

        &:before {
            background-color: var(--bs-white);
        }
    }

    .notes-stepper .stepper-step.stage-2 .step-circle {
        background-color: var(--bs-warning);

        &:before {
            background-color: var(--bs-white);
        }
    }

    .notes-stepper .stepper-step.stage-3 .step-circle {
        background-color: var(--bs-info);

        &:before {
            background-color: var(--bs-white);
        }
    }

    .notes-stepper .stepper-step.stage-4 .step-circle {
        background-color: var(--bs-danger);

        &:before {
            background-color: var(--bs-white);
        }
    }

    .notes-stepper .stepper-step.stage-5 .step-circle {
        background-color: var(--bs-success);

        &:before {
            background-color: var(--bs-white);
        }
    }
}

// Create Notes Modal
.createNotesModal .attachFile-wrapper {
    background-color: #cccccc;
}

// In House Notes Page CSS
.tabs-wrapper {
    .nav-tabs {
        background-color: var(--custom-bg);
        border-color: #464845;
        border-width: 1.5px;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;

        .nav-link {
            color: var(--text-secondary);
            padding: 8px 1rem;
            border: 0;
            border-bottom: 5px solid transparent;
            white-space: nowrap;

            @media(min-width:992px) {
                padding: 8px 2rem;
            }

            &:not(.active):hover,
            &:outline {
                border: 0 !important;
            }

            &.active {
                color: var(--bs-white);
                background-color: transparent;
                border-bottom: 5px solid var(--bs-primary) !important;
                font-weight: 500;
            }
        }
    }
}