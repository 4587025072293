/* select {
  width: 300px;
  height: 60px;
  margin-bottom: 10px;
} */

/* Center Column */

/* div#volume-indicators {
  padding: 10px;
  margin-top: 20px;
  width: 500px;
  text-align: left;
} */

/* div#volume-indicators > div {
  display: block;
  height: 20px;
  width: 0;
} */

/* Right Column */
/* .right-column {
  padding: 0 1.5em;
} */

/* #log {
  text-align: left;
  border: 1px solid #686865;
  padding: 10px;
  height: 9.5em;
  overflow-y: scroll;
} */

/* .log-entry {
  color: #686865;
  font-size: 1.25em;
  line-height: 1.25em;
  margin-left: 1em;
  text-indent: -1.25em;
  width: 90%;
} */

/* Other Styles */
.hide {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

button:disabled {
  cursor: not-allowed;
}
