$meeting-color1: #868686;

.AllMeetings-page {
    .date-wrapper {
        input {
            color: rgba(var(--bs-white-rgb), 0.80);
            border-color: var(--text-secondary);
        }

        button {
            background-color: #6e6e6d;
            border-color: #6e6e6d;
            min-width: 45px;
        }
    }

    .highlighter-wrapper {
        .highlight-menu .icon {
            height: 15px;
            width: 15px;
            min-width: 15px;

            &.icon-primary {
                // background: linear-gradient(168.68deg, rgb(0, 82, 201) 1.12%, rgb(10, 145, 219));
                background-image: linear-gradient(to bottom, rgb(0, 82, 201) 1.12%, rgb(10, 145, 219));
            }

            &.icon-warning {
                background-image: linear-gradient(to bottom, #938225, #b79728);
            }

            &.icon-success {
                background-image: linear-gradient(to bottom, #288856, #51be7d);
            }

            &.icon-danger {
                background-image: linear-gradient(to bottom, #892224, #9d2f30);
            }
        }
    }

    .meeting-card-wrapper {
        height: 60vh;
        min-height: 350px;
        overflow: auto;

        @media(min-width: 992px) {
            height: calc(100vh - 220px);
        }

        @media(min-width: 1200px) {
            height: calc(100vh - 165px);
        }

        .meeting-card {
            background-color: var(--theme-color3);

            &.selected {
                background: linear-gradient(168.68deg, rgb(0, 82, 201) 1.12%, rgb(10, 145, 219));

                .meeting-header {
                    background: transparent !important;
                    padding-top: 8px !important;
                }

                .meeting-body {
                    padding-top: 0 !important;
                }
            }

            .org-title {
                width: 70px;
            }
        }

        .meeting-card .meeting-header {
            background-color: #9d2f2e;
        }

        .meeting-card.today .meeting-header {
            background-image: linear-gradient(to right, #51be7d, #288856);
        }

        .meeting-card.upcoming .meeting-header {
            background-color: #9b8724;
        }
    }

    .meeting-details-section {

        @media(min-width: 992px) {
            height: calc(100vh - 220px);
        }

        @media(min-width: 1200px) {
            height: calc(100vh - 165px);
        }

        .info-card {
            background-color: var(--theme-color3);
            overflow: auto;


            @media(min-width: 768px) {
                .info-name {
                    display: flex;
                    gap: 30px;

                    // --bs-gutter-x: 28px;
                    .title-label {
                        width: 115px;
                        min-width: 115px;
                    }

                    .title-name {
                        width: calc(100% - 145px);
                    }
                }
            }
        }
    }
}

// Add new meeting modal css
.addNewMeetingModal {
    .form-label {
        color: $meeting-color1;
    }

    .custom-input {
        border-radius: .375rem;
        min-height: 46px;

        &::placeholder {
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }
    }

    .custom-input[type="date"]:invalid::-webkit-datetime-edit,
    .custom-input[type="time"]:invalid::-webkit-datetime-edit {
        color: $meeting-color1;
        font-size: 16px;
    }

    .custom-input[type="date"]:invalid::-webkit-calendar-picker-indicator {
        filter: invert(0.5);
    }

    .custom-input[type="time"]:invalid::-webkit-calendar-picker-indicator {
        filter: invert(0.5);
    }

    .custom-quill-editor {
        .ql-toolbar {
            .ql-picker {
                color: #666766;
                /* Replace with your desired color */
            }

            .ql-stroke {
                stroke: #666766;
                /* Replace with your desired color */
            }

            .ql-fill {
                fill: #666766;
                /* Replace with your desired color */
            }
        }

        // min-height: 200px;
        .ql-snow {
            border: 0;
        }

        .ql-toolbar {
            background-color: #f5f5f5;
            margin: 5px 10px;
        }

        .ql-container {
            color: #000;
            font-size: 16px;
            min-height: 100px;
            max-height: 200px;
            overflow: auto;

            .ql-blank::before {
                color: $meeting-color1;
                font-size: 16px;
                font-style: normal;
            }
        }
    }
}

// Cancelled meeting modal css
.cancelledMeeting {
    &.modal-dialog {
        max-width: 550px;
    }
}

/* HTML: <div className="meeting-loader"></div> */
.meeting-loader {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #25b09b;
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}