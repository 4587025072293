
.close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
}
.notification-wallet {
    background: linear-gradient(135deg, #007bff, #00aaff);
    color: white;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    animation: slideIn 0.5s ease-out;

}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.notification-icon {
    background-color: #ffc107;
    padding: 10px;
    border-radius: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-icon img {
    width: 24px;
    height: 24px;
}

.notification-content h3 {
    margin: 0 0 5px 0;
    font-size: 20px;
}

.notification-content p {
    margin: 5px 0;
    font-size: 14px;
}

.highlight {
    font-weight: bold;
}
