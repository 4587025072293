$meeting-color1: #868686;

.IssuesList-page {

    overflow: hidden;
    @media(min-width: 768px) {
        // max-height: calc(100vh - 56px);
    }

    .date-wrapper {

        input,
        select {
            color: rgba(var(--bs-white-rgb), 0.80);
            border-color: var(--text-secondary);

            &::-webkit-calendar-picker-indicator {
                filter: invert(1);
            }
        }

        button {
            background-color: #6e6e6d;
            border-color: var(--text-secondary);
            min-width: 45px;
        }
    }


    .issues-menu-section {
        overflow-x: auto;

        @media(min-width: 992px) {
            width: 150px;
        }

        li {
            a {
                background: transparent;
                color: var(--bs-white);
                white-space: nowrap;

                i {
                    opacity: 0.7;
                    margin-right: 3px;
                }

                &:hover {
                    background: var(--theme-color3);
                }
            }

            a.active {
                background: linear-gradient(168.68deg, rgb(0, 82, 201) 1.12%, rgb(10, 145, 219));

                i {
                    opacity: 1;
                }
            }
        }
    }

    .issues-details-section {
        @media(min-width: 992px) {
            width: calc(100% - 150px);
        }


        .issues-list-section {
            height: 350px;
            overflow: auto;

            @media(min-width: 992px) {
                height: calc(100vh - 325px);
            }

            @media(min-width: 1200px) {
                height: calc(100vh - 250px);
            }

            .issue-card {
                background-color: var(--theme-color3);

                &.selected {
                    background: linear-gradient(168.68deg, rgb(0, 82, 201) 1.12%, rgb(10, 145, 219));
                }
            }
        }

        .issues-details-section {
            overflow: auto;

            @media(min-width: 992px) {
                height: calc(100vh - 325px);
            }

            @media(min-width: 1200px) {
                height: calc(100vh - 250px);
            }

            .info-card {
                background-color: var(--theme-color3);
            }
        }
    }

    // common css for email section
    .files-wrapper {
        .file-btn {
            max-width: 200px;
        }
    }

    .custom-quill-editor {
        color: rgba(var(--bs-white-rgb), 0.85);

        // min-height: 200px;
        .ql-snow {
            border: 0;
        }

        .ql-toolbar {
            background-color: #959394;
        }

        .ql-container {
            overflow: auto;
            padding: 7px 0 10px;

            .ql-editor {
                font-size: 14px;
                padding: 5px 8px;

                &.ql-blank::before {
                    color: var(--text-secondary);
                    font-size: 15px;
                    font-style: normal;
                }
            }
        }
    }

    .submit-msg-wrapper {
        background-color: #595959;

        input {
            background-color: transparent;
        }
    }
}

.info-cards-wrapper {
    .info-card1 {
        background-color: rgba var(--bs-primary), 0.75;
    }
}

// Add new issue modal css
.addNewIssueModal {
    .form-label {
        color: $meeting-color1;
    }

    .custom-input {
        border-radius: .375rem;
        min-height: 46px;

        &::placeholder {
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            color: $meeting-color1;
            font-size: 16px;
            font-weight: normal;
        }
    }

    select:required:invalid {
        color: $meeting-color1 !important;
    }

    .custom-quill-editor {
        .ql-toolbar {
            .ql-picker {
                color: #666766;
                /* Replace with your desired color */
            }

            .ql-stroke {
                stroke: #666766;
                /* Replace with your desired color */
            }

            .ql-fill {
                fill: #666766;
                /* Replace with your desired color */
            }
        }

        // min-height: 200px;
        .ql-snow {
            border: 0;
        }

        .ql-toolbar {
            background-color: #f5f5f5;
            margin: 5px 10px;
        }

        .ql-container {
            color: #000;
            font-size: 16px;
            min-height: 100px;
            max-height: 200px;
            overflow: auto;

            .ql-blank::before {
                color: $meeting-color1;
                font-size: 16px;
                font-style: normal;
            }
        }
    }

    .attachFile-wrapper {
        background-color: #cccccc;
    }
}


// Reopen issue modal css
.reopenIssueModal {
    max-width: 600px;
}